import React, {useEffect, useState} from 'react';
import {Button, Card, Col, Form, Input, Radio, Row} from "antd";

function ConfigureStore(props: { store: any }) {
    const [form] = Form.useForm();
    const [initialData, setInitialData] = useState(props.store)
    const [updateData, setUpdateData] = useState(initialData)
    const [dirty, setDirty] = useState(JSON.stringify(updateData) === JSON.stringify(initialData));
    const handleValueChange = (e: any) => {
        setUpdateData({
            ...updateData,
            [e.target.name]: e.target.value
        });

    }

    useEffect(() => {
        setDirty(JSON.stringify(updateData) === JSON.stringify(initialData));
    }, [updateData]);

    useEffect(() => {
        setDirty(JSON.stringify(updateData) === JSON.stringify(initialData));
    }, [initialData]);

    const update = async () => {
        const resp = await fetch(`/stores/${props.store.id}/update`, {
            method: 'POST',
            headers: {
                'content-type': 'application/json'
            },
            body: JSON.stringify({store: updateData})
        });

        const newUpdate = await resp.json();
        setInitialData(newUpdate);
    }

    return (
        <Card title={'Shipping Information'}>
            <Form
                layout={'horizontal'}
                form={form}
                onChange={handleValueChange}
            >
                <Form.Item label="Shipping Display Name">
                    <Input placeholder="ACME Inc."
                           name={'shipping_name'}
                           defaultValue={initialData.shipping_name}/>
                </Form.Item>
                <Input.Group compact={true}>
                    <Form.Item label="Return Address" style={{width: '50%'}}>
                        <Input placeholder="100 E. Main St"
                               name={'return_address'}
                               defaultValue={initialData.return_address}/>
                    </Form.Item>
                    <Form.Item label="Return City">
                        <Input placeholder="City"
                               name={'return_city'}
                               defaultValue={initialData.return_city}/>
                    </Form.Item>
                    <Form.Item label="Return State">
                        <Input placeholder="State"
                               name={'return_state'}
                               defaultValue={initialData.return_state}/>
                    </Form.Item>
                    <Form.Item label="Return Zip">
                        <Input placeholder="12345"
                               name={'return_zip'}
                               defaultValue={initialData.return_zip}/>
                    </Form.Item>
                </Input.Group>
                <Row gutter={8}>
                   <Col span={12}>
                       <Form.Item label="Email">
                           <Input disabled
                                  defaultValue={initialData.email}/>
                       </Form.Item>
                   </Col>
                   <Col span={12}>
                       <Form.Item label="Phone Number">
                           <Input placeholder="333-555-1234"
                                  name={'phone_number'}
                                  defaultValue={initialData.phone_number}/>
                       </Form.Item>
                   </Col>
                </Row>
                <Form.Item>
                    <Button onClick={update} type="primary" disabled={dirty}>Update</Button>
                </Form.Item>
            </Form>
        </Card>
    )
}

export default ConfigureStore;
