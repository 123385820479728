import('../styles/application.scss');
import "bootstrap";
import("turbolinks");

require("@rails/ujs").start()
require('turbolinks').start()
require("@rails/activestorage").start()
require("channels")
require("jquery")
require("jquery-ui");

var componentRequireContext = require.context("components", true);
var ReactRailsUJS = require("react_ujs");
ReactRailsUJS.useContext(componentRequireContext);
