import React, {useState} from "react";
import {toast} from "react-toastify";
import {Modal, Spin} from "antd";
import {Content} from "antd/es/layout/layout";
import WelcomeScreen from "./WelcomeScreen";

function ProductSearch(props) {
    const [results, setResults] = useState([]);
    const [search, setSearch] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [itemIndex, setItemIndex] = useState(0)
    useState(false);

    const searchChange = (e) => {
        setSearch(e.target.value);
        $.ajax({
            type: "GET",
            url: `/search.json?q=${e.target.value}`,
            success: function (response) {
                setResults(response);
            },
            error: function (response) {
                toast.error(response.error);
            }
        })
    }

    const goToMockup = (event) => {
        event.preventDefault();
        setIsLoading(true);
        location.href = `/product/${event.target.id}`
    }

    return (
        <Content>
            <Modal title={'Welcome to InstaPrint!'}
                   width={'50%'}
                   closable={false}
                   cancelButtonProps={{disabled: true, hidden: true}}
                   okText={itemIndex === 4 ? 'Finish' : 'Next'}
                   onOk={() => {
                       if (itemIndex === 5) return;
                       setItemIndex(itemIndex + 1)
                   }}
                   visible={props.firstTimeUser && itemIndex !== 5}
            ><WelcomeScreen itemIndex={itemIndex}/></Modal>
            <div className="input-group flex-nowrap">
                <span className="input-group-text" id="addon-wrapping">Products Search:</span>
                <input id="search" type="search" className="form-control"
                       data-behavior="autocomplete"
                       value={search}
                       onChange={searchChange}
                       placeholder="..." aria-label="Enter product name" aria-describedby="addon-wrapping"/>
            </div>
            <Spin spinning={isLoading}>
                <div className='list-group'>
                    {results.map((result) => {
                        return (
                            <button type="button" key={result.id}
                                    id={result.id}
                                    disabled={isLoading}
                                    onClick={goToMockup}
                                    className='list-group-item list-group-item-action'>{result.title}</button>
                        )
                    })}
                </div>
            </Spin>
        </Content>)
}

export default ProductSearch;