const timediff = require('timediff');

export const timeSince = (updateAt: any) => {
    let time = timediff(updateAt, Date.now(), 'DHmS');
    if (time.days > 0) return `${time.days} day(s) ${time.hours} hours`
    if (time.hours > 0) return `${time.hours} hour(s) ${time.minutes} minute(s)`;
    return `${time.minutes} minute(s) ${time.seconds} second(s)`;
}

export const customerAddress = (customer: { address1: any; address2: any; city: any; province_code: any; zip: any; }) => {
    let str = `${customer.address1} \n`;
    if (customer.address2) str += `${customer.address2} \n`
    str += `${customer.city}, ${customer.province_code} ${customer.zip}`

    return str;
}

export const prettifyProductionCost = (cost) => {
    if (!cost) return "$0.00";
    return "$" + Number(cost).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,");
}

export const prettyDate = (date: any) => {
    return new Date(date).toLocaleString();
}
