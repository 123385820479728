import React, {useEffect, useState} from 'react';
import {Col, Row, Table, Tag} from "antd";
import UserOrder from "./UserOrder";
import {prettifyProductionCost, prettyDate} from "../shared/utils";

function UserOrders(props: any) {
    const [orderSelected, setOrderSelected] = useState(null);
    const [orders, setOrders] = useState([]);
    const getOrderStatus = (status: any) => {
        switch (status) {
            case 'closed':
                return <Tag color={'green'}>Fulfilled</Tag>;
            case 'cancelled':
                return <Tag color={'red'}>Cancelled</Tag>;
            default:
                return <Tag color={'yellow'}>Processing</Tag>;
        }
    }

    useEffect(() => {
        setOrders(props.orders.map((order: any) => {
            return {
                key: order.id,
                id: `#${order.short_id}`,
                productionUpdateDate: order.production_update_date ? prettyDate(order.production_update_date) : order.production_status,
                customer: order.customer,
                productionCost: prettifyProductionCost(order.production_cost),
                status: getOrderStatus(order.status),
                order: order,
            }
        }).sort((a: any, b: any) => b.key - a.key))
    }, [props.orders]);

    const orderColumns = [
        {
            title: 'Order #',
            dataIndex: 'id',
        },
        {
            title: 'Sent to production',
            dataIndex: 'productionUpdateDate'
        },
        {
            title: 'Customer',
            dataIndex: 'customer',
            width: 200
        },
        {
            title: 'Production Cost',
            dataIndex: 'productionCost',
            width: 150,
        },
        {
            title: 'Status',
            dataIndex: 'status',
            width: 200,
        },
        {
            title: 'Link',
            dataIndex: 'link',
            width: 200,
            render: (key: any, dataSet: any) => (
                <div className={'btn btn-sm btn-outline-primary'}
                     onClick={() => setOrderSelected(dataSet.order)}>View
                </div>
            )
        }
    ]

    return (
        <Row className={'mt-2'}>
            <Col span={24}>
                <Table columns={orderColumns as any}
                       dataSource={orders}
                />
            </Col>
            {orderSelected &&
                <Col span={24}>
                    <UserOrder order={orderSelected}/>
                </Col>
            }
        </Row>
    )
}

export default UserOrders