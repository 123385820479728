import React from 'react';

function ShopifyProductSubscribe(props) {
    const handleFormSubmit = (e) => {
        e.preventDefault();
        let formElement = $("#form-submit-button");
        formElement.prop('disabled', true);

        props.handleFormSubmit(() => formElement.prop('disabled', false));
    }

    return (<div>
        <div className="card-header">
            You need to subscribe to this product before we can add it to your Shopify store.
        </div>
        <div className="card-body">
            <p>This will ask you to authorize us to fufill the product and associate the cost to fulfill each product.</p>
            <form className='row g-3' onSubmit={handleFormSubmit}>
                <input className='btn btn-primary' type='submit' value='Subscribe' id='form-submit-button'/>
            </form>
        </div>
    </div>)
}

export default ShopifyProductSubscribe