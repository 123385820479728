import React from "react";

function OrderVariant(props) {
    let lookupQtyField = props.lookupQtyField(props.variant.id);
    const showLarger = (shouldntShow: boolean) => {
        let variantImage = $(`#${props.variant.id}`);
        if (shouldntShow) return variantImage.hide();
        variantImage.show();
    }

    return (
        <tr className={props.variant.stock < lookupQtyField ? 'table-danger' : 'table-success'}>
            <td onMouseLeave={() => showLarger(true)} onMouseEnter={() => showLarger(false)}>
                <img src={props.variantImages[props.variant.id]}
                     style={{width: "40px"}}
                     className="rounded mx-auto d-block"
                     onMouseLeave={() => showLarger(true)}
                     alt={props.variant.name}/>
                <div className="thumbnail" id={props.variant.id} onMouseLeave={() => showLarger(true)}>
                    <img src={props.variantImages[props.variant.id]} alt={props.variant.name}/>
                </div>
            </td>
            <td>{props.store.name}</td>
            <td>{props.variant.size}</td>
            <td>{props.variant.color}</td>
            <td>{props.variant.stock}</td>
            <td>{lookupQtyField}</td>
        </tr>
    )
}

export default OrderVariant