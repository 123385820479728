import React, {useEffect, useRef, useState} from "react";
import {toast} from "react-toastify";
import {blankVariant, ProductInterface, VariantInterface} from "../deo/ProductData";
import Variants from "./Variants";
import axios from "axios";
import {Button} from "antd";

function Product(props: {
                     product: ProductInterface,
                     variants: VariantInterface[],
                     isNew?: boolean,
                     handleSave?: any
                 }
) {
    const [data, setData] = useState(props);
    const [variants, setVariants] = useState(props.variants)
    const [variantQty, setVariantQty] = useState([Array.from(Array(props.variants.length + 1).keys())]);
    const printAreaRef = useRef(null);
    const printContainerRef = useRef(null);
    const mainImageRef = useRef(null);
    const [productLoading, setProductLoading] = useState(false);

    const doCreateNewProduct = (e: any) => {
        e.preventDefault();
        $(e.target).prop('disabled', true);

        props.handleSave(data, (resp: any) => {
            $(e.target).prop('disabled', false);
            if (resp) toast.error(resp);
            else toast.success('Product created!');
        });
    }

    const doUpdateProduct = () => {
        setProductLoading(true);

        axios.post(`/admin/products/${data.product.id}`, {
            data,
            print_areas: {
                box_height: printContainerRef.current.clientHeight,
                box_width: printContainerRef.current.clientWidth,
                print_area_top: printAreaRef.current.offsetTop - printContainerRef.current.offsetTop,
                print_area_left: printAreaRef.current.offsetLeft,
                print_area_width: printAreaRef.current.clientWidth,
                print_area_height: printAreaRef.current.clientHeight
            }
        })
            .then((resp) => {
                toast.success('Product Updated!');
                setProductLoading(false);
            })
            .catch((err) => toast.error(err))
    }

    const fieldChange = (e: any): any => {
        setData({
            ...data,
            product: {
                ...data.product,
                [e.target.name]: e.target.value
            },
        });
    }

    const updateVariantData = (variantUpdates) => {
        setData({
            ...data,
            variants: variantUpdates
        })
    }

    const uploadImage = (e, callback) => {
        let file = e.target.files[0];
        const formData = new FormData();
        formData.append("photo", file);

        $.ajax({
            type: "POST",
            url: "/upload-image",
            data: formData,
            contentType: false,
            processData: false,
            success: function (response) {
                callback(response)
            },
            error: function () {
                toast.error('Error uploading image');
            }
        });
    }

    const variantAddClicked = () => {
        setVariants([
                ...data.variants,
                blankVariant
            ]
        )
        setVariantQty(Array.from(Array(variantQty.length + 1).keys()) as []);
    }

    const mainImageChanged = (e) => {
        uploadImage(e, (resp) => {
            setData({
                ...data,
                product: {
                    ...data.product,
                    image: resp.file,
                }
            })
        })
    }

    useEffect(() => {
        setTimeout(() => {
            drawPrintFileArea()
        }, 1000)
    }, [data.product.image])

    useEffect(() => {
        function handleResize() {
            drawPrintFileArea();
        }

        window.addEventListener("resize", handleResize);
        handleResize();
        return () => window.removeEventListener("resize", handleResize);
    }, [])

    const drawPrintFileArea = () => {
        let {width, height} = mainImageRef.current;
        let containerWidth = 100;
        let containerHeight = 200;
        let templateWidth = 100;
        let templateHeight = 200;
        let containerTop = 5;
        let containerLeft = 5;

        if (data.product.print_data) {
            templateWidth = data.product.print_data.template_width;
            templateHeight = data.product.print_data.template_height;
            containerHeight = data.product.print_data.height;
            containerWidth = data.product.print_data.width;
            containerTop = data.product.print_data.top;
            containerLeft = data.product.print_data.left;
        }

        const containerHeightRatio = height / templateHeight;
        const containerWidthRatio = width / templateWidth;

        setData({
            ...data,
            product: {
                ...data.product,
                print_data: {
                    width: `${containerWidth * containerWidthRatio}px`,
                    height: `${containerHeight * containerHeightRatio}px`,
                    top: `${containerTop * containerHeightRatio}px`,
                    left: `${containerLeft * containerWidthRatio}px`
                }
            }
        });

        $('#print-area').draggable();
        $('#print-area').resizable();
    }

    return (
        <div className="row">
            <div className="col-12 mt-2">
                <div className="card">
                    <div className="card-header">
                        <div className={"row"}>
                            <div className={"col-3"}>
                                <input onChange={fieldChange} type="string"
                                       className="form-control"
                                       name="title"
                                       placeholder={"Product Title"}
                                       value={data.product.title}/>
                            </div>
                            <div className={"col-3"}>
                                <input onChange={fieldChange} type="string"
                                       className="form-control"
                                       name="brand"
                                       placeholder={"Product Brand"}
                                       value={data.product.brand}/>
                            </div>
                            <div className={"col-3"}>
                                <input onChange={fieldChange} type="string"
                                       className="form-control"
                                       name="model"
                                       placeholder={"Product Model"}
                                       value={data.product.model}/>
                            </div>
                        </div>
                    </div>
                    <div className="card-body">
                        <div className="row">
                            <div className="col-3" ref={printContainerRef}>
                                <input className="form-control"
                                       onChange={mainImageChanged}
                                       hidden={data.product.image && data.product.image.length > 0}
                                       type="file" id="mainImage"/>
                                <img src={data.product.image}
                                     id={'main-image'}
                                     ref={mainImageRef}
                                     hidden={!data.product.image || !data.product.image.length}
                                     className="rounded float-start img-thumbnail"
                                     alt={data.product.title}/>
                                <div id="print-area"
                                     ref={printAreaRef}
                                     style={data.product.print_data}
                                     hidden={!data.product.image || !data.product.image.length}
                                     className="dotted-line"></div>
                            </div>
                            <div className="col-9">
                                <h5 className="card-title-header">Variant(s)</h5>
                                <Variants variants={variants} isNew={props.isNew}
                                          uploadImage={uploadImage}
                                          updateVariantData={updateVariantData}
                                          productId={data.product.id}
                                />
                                <button hidden={!props.isNew}
                                        className={'btn btn-outline-primary'} onClick={variantAddClicked}>Add Variant
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={"row mt-2"}>
                    <Button hidden={!props.isNew}
                            size={'large'}
                            type={'primary'}
                            loading={productLoading}
                            onClick={doCreateNewProduct}>Save
                    </Button>
                    <Button hidden={props.isNew}
                            size={'large'}
                            loading={productLoading}
                            onClick={doUpdateProduct}
                            type={'primary'}>Update Product
                    </Button>
                </div>
            </div>
        </div>
    );
}

export default Product;