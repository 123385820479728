export interface ProductInterface {
    title: string
    image: string
    id: string
    brand: string
    model: string
    print_data?: any
}

export interface VariantInterface {
    size: string
    color: string
    color_code: string
    external_id: string
    stock: number
    price: string
    id?: string | number
    image: string,
    key?: string
}

export const blankVariant = {size: '', color_code: '', color: '', external_id: '', price: '', stock: 0, image: '', id: 0};