import React, {useState} from "react";
import {toast} from "react-toastify";

function ShopifyLogin() {
    const [data, setData] = useState('');

    const doLogin = (e) => {
        e.preventDefault();
        let authButton = $('#auth-button');
        authButton.prop('disabled', true);

        $.ajax({
            type: "POST",
            url: `/shopify/auth_external`,
            data: {
                shop: `${data}.myshopify.com`,
            },
            error: function (response) {
                toast.error(response);
                authButton.prop('disabled', false);
            }
        })
    }

    return (<div className="row justify-content-center">
        <div className="col-6 mt-5">
            <h3>Authenticate Store</h3>
            <form onSubmit={doLogin}>
                <div className="input-group mb-3">
                    <input type="text" name="store" value={data}
                           onChange={(e) => setData(e.target.value)}
                           className='form-control'/>
                    <span className="input-group-text" id="basic-addon2">.myshopify.com</span>
                </div>
                <div className="d-grid gap-2">
                    <input id={"auth-button"} type={"submit"} value={"Authenticate"} onClick={doLogin} className="btn btn-primary" />
                </div>
            </form>
        </div>
    </div>)
}

export default ShopifyLogin;