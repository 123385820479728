import React, {useEffect, useRef, useState} from 'react';
import {Content} from "antd/es/layout/layout";
import {Button, Card, Col, Form, Image, Row, Select, Space, Spin, Tag, Typography, Upload} from "antd";
import {UploadOutlined} from "@ant-design/icons";
import {toast} from "react-toastify";
import axios from "axios";

export default function Product(props) {
    const [productTemplateLoading, setProductTemplateLoading] = useState(true);
    const [productTemplates, setProductTemplates] = useState({templates: []})
    const [productPrintFiles, setProductPrintFiles] = useState({available_placements: {}})
    const [type, setType] = useState('');
    const [placementText, setPlacementText] = useState('');
    const [currentPlacement, setCurrentPlacement] = useState(0);
    const [uploadedImageUrl, setUploadedImageUrl] = useState('');
    const printAreaRef = useRef(null);

    useEffect(() => {
        $.ajax({
            type: "GET",
            url: `/product_templates/${props.product.id}`,
            success: function (response) {
                setProductTemplates(response);
                setProductTemplateLoading(false);
            },
            error: function (response) {
                setProductTemplateLoading(false);
                toast.error(response.error);
            }
        })
    }, [setProductTemplates])

    useEffect(() => {
        $.ajax({
            type: "GET",
            url: `/product_printfiles/${props.product.external_id}`,
            success: function (response) {
                setProductPrintFiles(response);
                setPlacementText(Object.values(response.available_placements)[0] as string)
            },
            error: function (response) {
                toast.error(response.error);
            }
        })
    }, [setProductPrintFiles])

    const onFinish = (values) => {
        switch (type) {
            case 'blank': {
                $.ajax({
                    type: "POST",
                    url: `/product/${props.product.id}/print_blank`,
                    data: {
                        formData: values,
                    },
                    success: function (response) {
                        location.href = `/product/${response.id}/mockups`;
                    },
                    error: function (response) {
                        toast.error(response);
                        setType('');
                    }
                })
                break;
            }
            case 'mockup': {
                $.ajax({
                    type: "POST",
                    url: `/product/${props.product.id}/print`,
                    data: {
                        formData: values,
                        placement: Object.keys(productPrintFiles.available_placements).find(key => productPrintFiles.available_placements[key] === placementText),
                        image: uploadedImageUrl,
                        printArea: getImageData()
                    },
                    success: function (response) {
                        location.href = `/product/${response.id}/mockups`;
                    },
                    error: function (response) {
                        toast.error(response);
                    }
                })
                break;
            }
            default:
                setType('');
                return
        }
    }

    const updatePlacement = (idx, print) => {
        setCurrentPlacement(idx);
        setStyleForDottedLine(idx);
        setPlacementText(print);
    }

    const initialPlacement = (response) => {
        let img = document.createElement("img");
        let div = document.createElement('div');
        div.className = 'resize-div';
        img.id = 'uploaded-file';
        img.src = response.file;
        img.style.maxWidth = `${printAreaRef.current.clientHeight}px`;
        setUploadedImageUrl(response.file);

        div.appendChild(img);
        $("#print-area").append(div);
        $('#uploaded-file').draggable({
            containment: '#print-area',
            aspectRatio: true,
        });

        $('.resize-div').resizable({
            containment: '#print-area',
        });
    }

    const uploadImageForMockup = (file) => {
        setStyleForDottedLine(currentPlacement);
        let formData = new FormData();

        formData.append("photo", file);
        formData.append("id", props.product.id);
        formData.append("printfiles", productPrintFiles as any);
        formData.append("templateData", productTemplates.templates[currentPlacement]);

        axios.post('/upload-image', formData)
            .then((response) => initialPlacement(response.data))
            .catch((response) => toast.error(response));
    }

    const heightRatioOfPrintArea = (templateDataSet) => {
        let templateWidth = templateDataSet.template_width;
        let templateHeight = templateDataSet.template_height;
        let {width, height} = $('#main-image>img')[0];
        let widthRatio = width / templateWidth;
        let heightRatio = height / templateHeight;
        return {widthRatio, heightRatio};
    }

    const setStyleForDottedLine = (indexOfPrintFile) => {
        let templateDataSet = productTemplates.templates[indexOfPrintFile];
        let printAreaWidth = templateDataSet.print_area_width;
        let printAreaHeight = templateDataSet.print_area_height;
        let printAreaTop = templateDataSet.print_area_top;
        let printAreaLeft = templateDataSet.print_area_left;
        let {widthRatio, heightRatio} = heightRatioOfPrintArea(templateDataSet);

        $(`#print-area`).attr('style',
            `width: ${printAreaWidth * widthRatio}px;
        height: ${printAreaHeight * heightRatio}px;
        top: ${printAreaTop * heightRatio}px;
        left: ${printAreaLeft * widthRatio}px`);
    }

    const getImageData = () => {
        let printArea = printAreaRef.current;
        let imageArea = $(`#uploaded-file`);
        let areaW = printArea.clientWidth;
        let areaH = printArea.clientHeight;
        let imageW = imageArea.css('width').match(/\d+\S?\d+/)[0];
        let imageH = imageArea.css('height').match(/\d+\S?\d+/)[0];
        let imageT = imageArea.css('top').match(/\d+\S?\d+/);
        imageT ? imageT = imageT[0] : imageT = 0;
        let imageL = imageArea.css('left').match(/\d+\S?\d+/);
        imageL ? imageL = imageL[0] : imageL = 0;

        return {
            width: imageW,
            height: imageH,
            top: imageT,
            left: imageL,
            area_width: areaW,
            area_height: areaH,
            area_left: printArea.offsetLeft,
            area_top: printArea.offsetTop
        }
    }

    return (
        <Content>
            <Row gutter={8}>
                <Col span={8}>
                    <Spin spinning={productTemplateLoading}>
                        <Image
                            style={{border: '1px solid #f0f0f0'}}
                            src={productTemplates.templates.length ?
                                productTemplates.templates[currentPlacement]['image_url'] : props.product.image}
                            id="main-image" preview={false} alt={props.product.title}/>
                        <div id="print-area" ref={printAreaRef} className="dotted-line"></div>
                    </Spin>
                </Col>
                <Col span={16}>
                    <Card title={props.product.title}>
                        <Typography.Title level={5}>
                            Price Range
                        </Typography.Title>
                        <p>{props.product.price_range}</p>
                        <Form onFinishFailed={() => setType('')} onFinish={onFinish}>
                            <Typography.Title level={5}>Select Sizes</Typography.Title>
                            <Form.Item name={'sizes'}
                                       rules={[{required: true, message: 'At least 1 size is required'}]}>
                                <Select
                                    mode="multiple"
                                    allowClear
                                    style={{width: '100%'}}
                                    placeholder="Please select a size..."
                                >
                                    {props.product.variant_sizes.map((size, idx) => {
                                        return (<Select.Option key={size}>{size}</Select.Option>)
                                    })}
                                </Select>
                            </Form.Item>
                            <Typography.Title level={5}>Select Colors</Typography.Title>
                            <Form.Item name={'colors'}
                                       rules={[{required: true, message: 'At least 1 color is required'}]}>
                                <Select
                                    mode="multiple"
                                    allowClear
                                    placeholder="Please select a color..."
                                >
                                    {props.product.variant_colors.map((color, idx) => {
                                        return (<Select.Option key={color.color}><Tag key={idx}
                                                                                      color={color.color_code}>{color.color}</Tag></Select.Option>)
                                    })}
                                </Select>
                            </Form.Item>
                            <Form.Item hidden={!Object.values(productPrintFiles.available_placements).length}>
                                <Typography.Title level={5}>Select Placements</Typography.Title>
                                {Object.values(productPrintFiles.available_placements).map((print, idx) => {
                                    return (<Tag style={{cursor: 'pointer'}}
                                                 color={idx === currentPlacement ? 'blue' : 'grey'} key={idx}
                                                 onClick={() => updatePlacement(idx, print)}>{print}</Tag>)
                                })}
                            </Form.Item>
                            <Typography.Title level={5}>Upload Custom Artwork</Typography.Title>
                            <Upload
                                multiple={false}
                                disabled={uploadedImageUrl !== ''}
                                beforeUpload={uploadImageForMockup}
                                onRemove={(e) => console.log('remove', e)}>
                                <Button icon={<UploadOutlined/>} disabled={productTemplateLoading}>
                                    Click to Upload</Button>
                            </Upload>
                            <Space style={{marginTop: '1rem'}}>
                                <Button type={'primary'} disabled={uploadedImageUrl === ''}
                                        loading={type === 'mockup'}
                                        onClick={() => setType('mockup')} htmlType={'submit'}>
                                    Proceed to mockups</Button>
                                <Button type={'primary'}
                                        loading={type === 'blank'}
                                        onClick={() => setType('blank')}
                                        htmlType={'submit'}>
                                    Proceed with blanks</Button>
                            </Space>
                        </Form>
                    </Card>
                </Col>
            </Row>
        </Content>
    )
}