import React, {useEffect, useState} from 'react';
import MarkdownIt from 'markdown-it';
import MdEditor from 'react-markdown-editor-lite';

function ShopifyProductSave(props) {
    const [data, setData] = useState(
        {
            title: props.product.title || '',
            description: props.product.description || '',
            description_html: props.product.description_html || '',
            variantColors: props.product.variant_colors,
            variantSizes: props.product.variant_sizes,
            colorsActive: props.product.selected_colors,
            sizesActive: props.product.selected_sizes,
            tags: props.product.tags || '',
            is_bundle: props.product.is_bundle,
            bundle_qty: props.product.bundle_qty || 0,
            costs: props.product.costs,
            price: props.product.price,
        });

    const [tagData, setTagData] = useState({
        tags: props.product.tags ? props.product.tags.split(',') : [],
        tagValue: '',
    })

    const [itemCost, setItemCost] = useState('');

    const handleFormSubmit = (e) => {
        e.preventDefault();

        let formElement = $("#form-submit-button");
        formElement.prop('disabled', true);

        props.handleFormSubmit(data, () => {
            formElement.prop('disabled', false);
        })
    }

    const handleTagSubmit = (del: boolean = false, index: number) => {
        if (del) tagData.tags.splice(index, 1);
        else tagData.tags.push(tagData.tagValue);
        fieldChange({target: {value: tagData.tags.join(), name: 'tags'}})
        setTagData({
            ...tagData,
            tagValue: '',
            tags: tagData.tags
        })
    }

    const whichKeyPressedInInput = (e) => {
        if (e.charCode !== 13) return;
        e.preventDefault();

        handleTagSubmit(false, 0);
    }

    const mdParser = new MarkdownIt();

    const fieldChange = (e: any): any => {
        setData({
            ...data,
            [e.target.name]: e.target.type === 'checkbox' ? e.target.checked : e.target.value
        });
    }

    const handleEditorChange = ({html, text}) => {
        setData({
            ...data,
            description: text,
            description_html: html,
        })
    }

    const checkBoxChange = (e: any): any => {
        if (data[e.target.name].indexOf(e.target.id) > -1) {
            setData({
                ...data,
                [e.target.name]: data[e.target.name].filter((item) => item !== e.target.id)
            })
        } else {
            setData({
                ...data,
                [e.target.name]: [
                    ...data[e.target.name],
                    e.target.id
                ]
            })
        }
    }

    useEffect(() => {
        let qtyUp = data.bundle_qty || 1;
        if (qtyUp > 1 && data.is_bundle) {
            if (data.costs.length > 1) {
                setItemCost(`$${data.costs[0] * qtyUp} - $${data.costs[1] * qtyUp} per bundle of ${qtyUp}`);
            } else {
                setItemCost(`$${data.costs[0] * qtyUp} per bundle of ${qtyUp}`);
            }
        } else {
            if (data.costs.length > 1) {
                setItemCost(`$${data.costs[0]} - $${data.costs[1]} per item`);
            } else {
                setItemCost(`$${data.costs[0]} per item`);
            }
        }
    }, [data.bundle_qty, data.is_bundle])

    return (
        <div>
            <div className="card-header">
                Details and Descriptions
            </div>
            <div className="card-body">
                <form className='row g-3' onSubmit={handleFormSubmit}>
                    <div className={"mb-2"}>
                        <label className='form-label'>Product Title</label>
                        <input type='text' name='title' className='form-control'
                               onChange={fieldChange}
                               value={data.title}/>
                    </div>
                    <div className={"row"}>
                        <div className={'col-4'}>
                            <label className='form-label'>Cost</label>
                            <input type='text' name='cost' className='form-control'
                                   onChange={fieldChange}
                                   value={itemCost}
                                   disabled={true}/>
                        </div>
                        <div className={'col-4'}>
                            <label className='form-label'>Sale Price</label>
                            <input type='text' name='price' className='form-control'
                                   onChange={fieldChange}
                                   value={data.price} />
                        </div>
                        <div className={"col-2"}>
                            <div>Is Bundle?</div>
                            <input type='checkbox'
                                   className='form-check-input'
                                   onChange={fieldChange}
                                   checked={data.is_bundle}
                                   name={"is_bundle"}/>
                        </div>
                        <div className={"col-2"}>
                            <label className='form-label'>Bundle Qty</label>
                            <input type='number' name='bundle_qty' className='form-control'
                                   onChange={fieldChange}
                                   disabled={!data.is_bundle}
                                   value={data.bundle_qty}/>
                        </div>
                    </div>
                    <div>
                        <div>Sizes</div>
                        {data.variantSizes.map((size, index) => {
                            return (
                                <div className="form-check form-check-inline" key={index}>
                                    <input type={"checkbox"}
                                           disabled={props.disabledCheckBoxes}
                                           checked={data.sizesActive.indexOf(size) > -1}
                                           onChange={checkBoxChange}
                                           className={"form-check-input"} id={size}
                                           name="sizesActive"/>
                                    <label className={"form-check-label middle-right"}>{size}</label>
                                </div>
                            )
                        })}
                    </div>
                    <div>
                        <div>Colors</div>
                        {data.variantColors.map((color, index) => {
                            return (
                                <div className="form-check form-check-inline" key={index}>
                                    <input type={"checkbox"}
                                           checked={data.colorsActive.indexOf(color.color) > -1}
                                           disabled={props.disabledCheckBoxes}
                                           onChange={checkBoxChange}
                                           style={{background: color.color_code}}
                                           className={"form-check-input color-box"} id={color.color}
                                           name="colorsActive"/>
                                    <label className={"form-check-label middle-right"}>{color.color}</label>
                                </div>
                            )
                        })}
                    </div>
                    <div>
                        <label className='form-label'>Tags</label>
                        <div className={"mb-2"}>
                            {data.tags.split(',').map((tag, idx) => {
                                return (
                                    <span key={idx}
                                          onClick={() => handleTagSubmit(true, idx)}
                                          className={"badge bg-success mr-5px"}>{tag}</span>
                                )
                            })}
                        </div>
                        <input className={'form-control'}
                               placeholder={'Enter tag...'}
                               onChange={(e) => setTagData({...tagData, tagValue: e.target.value})}
                               onKeyPress={whichKeyPressedInInput}
                               value={tagData.tagValue}/>
                    </div>
                    <div className="mb-3">
                        <label className='form-label'>Description</label>
                        <MdEditor style={{height: '250px'}}
                                  renderHTML={text => mdParser.render(text)}
                                  value={data.description}
                                  onChange={handleEditorChange}/>
                    </div>
                    <input className='btn btn-primary' type='submit' value={props.saveText}
                           id="form-submit-button"/>
                </form>
            </div>
        </div>
    )
}

export default ShopifyProductSave