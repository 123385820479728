import React, {useEffect, useRef} from 'react';
import {Content} from "antd/es/layout/layout";
import {Avatar, Card, Carousel} from "antd";
import {EditOutlined, EllipsisOutlined, SettingOutlined} from "@ant-design/icons";
import Meta from "antd/es/card/Meta";

const contentStyle: React.CSSProperties = {
    height: '160px',
    color: '#fff',
    lineHeight: '160px',
    textAlign: 'center',
    background: '#364d79',
};

export default function WelcomeScreen(props) {
    const carousel = useRef(null);
    useEffect(() => {
        if (props.itemIndex === 0) return;
        carousel.current.goTo(props.itemIndex, true);
    }, [props.itemIndex])
    return (
        <Content>
            <Carousel ref={carousel}>
                <div style={contentStyle}>
                    <Card
                        style={{width: '100%'}}
                        cover={
                            <img
                                alt="search"
                                src="https://dev-instaprint-uploads.s3.us-west-2.amazonaws.com/development/Screen+Shot+2022-06-13+at+8.46.01+PM.png"
                            />
                        }
                    >
                        <Meta
                            title="Product Search"
                            description="Here you can search for a product in our inventory. Begin by typing in a few letters to find the product you are looking for."
                        />
                    </Card>
                </div>
                <div style={contentStyle}>
                    <Card
                        style={{width: '100%'}}
                        cover={
                            <img
                                alt="product"
                                src="https://dev-instaprint-uploads.s3.us-west-2.amazonaws.com/development/Screen+Shot+2022-06-13+at+8.46.23+PM.png"
                            />
                        }
                    >
                        <Meta
                            title="Product Customization"
                            description="Here you can customize your apparel by selecting sizes, colors and custom artwork."
                        />
                    </Card>
                </div>
                <div style={contentStyle}>
                    <Card
                        style={{width: '100%'}}
                        cover={
                            <img
                                alt="order page"
                                src="https://dev-instaprint-uploads.s3.us-west-2.amazonaws.com/development/Screen+Shot+2022-06-13+at+8.48.14+PM.png"
                            />
                        }
                    >
                        <Meta
                            title="Order Control"
                            description="Look under the orders tab to see the orders that have been placed in your store for fulfillment through InstaPrint."
                        />
                    </Card>
                </div>
                <div style={contentStyle}>
                    <Card
                        style={{width: '100%'}}
                        cover={
                            <img
                                alt="product page"
                                src="https://dev-instaprint-uploads.s3.us-west-2.amazonaws.com/development/Screen+Shot+2022-06-13+at+8.56.16+PM.png"
                            />
                        }
                    >
                        <Meta
                            title="Product List"
                            description="The product tab will show you a list of products under your account. From here you can edit the products you show on Shopify."
                        />
                    </Card>
                </div>
                <div style={contentStyle}>
                    <Card
                        style={{width: '100%'}}
                        cover={
                            <img
                                alt="product edit page"
                                src="https://dev-instaprint-uploads.s3.us-west-2.amazonaws.com/development/Screen+Shot+2022-06-13+at+8.56.24+PM.png"
                            />
                        }
                    >
                        <Meta
                            title="Edit Product"
                            description="When clicking edit on a product, you can update the variants you list online."
                        />
                    </Card>
                </div>
            </Carousel>
        </Content>
    )
}