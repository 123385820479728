import React from "react";
import {toast} from "react-toastify";
import ShopifyProductSave from "./ShopifyProductSave";

function ShopifyProduct(props: { mockup: any, product: any, store_id: any }) {
    const associateImages = (shopifyResponseId: any, callback: any) => {
        $.ajax({
            type: "POST",
            url: `/product/${props.product.id}/save_shopify/associate_images`,
            data: {
                shopify_response_id: shopifyResponseId,
                mockup_id: props.mockup.id,
                store_id: props.store_id
            },
            success: function (response) {
                checkResponseStatus(
                    response['shopify_response_id'], callback, 'Complete', callback
                )
            },
            error: function (response) {
                toast.error(response.error || 'Something went wrong!');
                callback();
            }
        })
    }

    const doSubmit = (formData: any, callback: any) => {
        $.ajax({
            type: "POST",
            url: `/stores/${props.store_id}/product/${props.product.id}`,
            data: {
                product: formData,
                mockup_id: props.mockup.id,
            },
            success: function (response) {
                toast.success('Product Save Started');
                checkResponseStatus(
                    response['shopify_response_id'], callback, 'Product Saved - Starting Image association', associateImages
                )
            },
            error: function (response) {
                toast.error(response.error || 'Something went wrong!');
                callback();
            }
        })
    }

    const checkResponseStatus = (responseId: any, callback: any, statusMessage: any, method: any) => {
        $.ajax({
            type: "GET",
            url: `/status/${responseId}`,
            success: function (response) {
                if (response['status'] === 'complete') {
                    toast.success(statusMessage);
                    method(responseId, callback)
                } else if (response['status'] === 'failed') {
                    toast.error('Process failed');
                    callback();
                }
                else {
                    setTimeout(function () {
                        checkResponseStatus(responseId, callback, statusMessage, method);
                    }, 2000);
                }
            },
            error: function (response) {
                toast.error(response.error || 'Something went wrong!');
                callback();
            }
        })
    }

    return (
        <div className="row mt-4">
            <div className="col-5">
                <div className="row">
                    {props.mockup.mockups.map((mockup, idx) => {
                        return (
                            <div key={idx} className="col-4 mb-2">
                                <div className="card">
                                    <img src={mockup.mockup_url} className="card-img-top"/>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
            <div className="col-7">
                <ShopifyProductSave product={props.product}
                                    saveText={'Save Shopify Product'}
                                    handleFormSubmit={doSubmit}/>
            </div>
        </div>
    )
}

export default ShopifyProduct