import React, {useState, useEffect} from 'react';
import {toast} from "react-toastify";
import ShopifyProductSave from "../product/ShopifyProductSave";

function MockupProduct(props) {
    const [mockups, setMockups] = useState(props.mockup.mockups || [])

    const submitForm = (dataSet) => {
        doButton(false);

        $.ajax({
            type: "POST",
            url: `/product/${props.product.id}/save`,
            data: {
                product: dataSet,
                store_id: props.store_id,
                mockup_id: props.mockup.id
            },
            success: function (response) {
                toast.success('Product Saved');
                location.href = `/stores/${props.store_id}/product/${response['product_id']}`
            },
            error: function (response) {
                doButton(true);
                toast.error(response.error || 'Something went wrong!');
            }
        })
    }

    const doButton = (isOff) => {
        if (isOff) {
            $('.btn.btn-primary').show().prop('disabled', false);
            $('#submit-product-store-spinner').hide();
        } else {
            $('.btn.btn-primary').hide();
            $('#submit-product-store-spinner').show();
        }
    }

    const checkPrintData = () => {
        if (mockups.length) return $('#spinner').remove();

        $.ajax({
            type: "GET",
            url: `/print/${props.mockup.id}/mockup_status`,
            success: function (response) {
                if (response.mockups.length) {
                    $('#spinner').remove();
                    let mockupData = response.mockups;
                    return setMockups(mockupData);
                } else {
                    setTimeout(function () {
                        checkPrintData();
                    }, 2000);
                }
            },
            error: function (response) {
                console.log(response);
            }
        });
    }

    useEffect(() => {
        checkPrintData();
    });

    return (
        <div className="row mt-4">
            <div className="col-4">
                <div id='spinner' className="d-flex justify-content-center">
                    <strong>Loading... </strong>
                    <div className="spinner-grow text-primary" role="status"/>
                </div>
                <div id="mockup-row" className="row">
                    {mockups.map((mockup, idx) => {
                        return (
                            <div key={idx} className={"col-4 mb-2"}>
                                <div className={"card"}>
                                    <img className={"card-img-top"}
                                         src={mockup.mockup_url}/>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
            <div className="col-8">
                <ShopifyProductSave product={props.product}
                                    disabledCheckBoxes={true}
                                    saveText={'Save Product'}
                                    handleFormSubmit={submitForm}/>
            </div>
        </div>
    )
}

export default MockupProduct;