import React, {useEffect, useState} from "react";
import BillingData from "../order/BillingData";
import {Image, Table, Tag, Row, Col} from "antd";
import {Content} from "antd/es/layout/layout";
import Order from "../admin/Order";
import {customerAddress, timeSince} from "../shared/utils";

export const UserOrder = (props: { order: any }) => {
    const [orderRender, setOrderRender] = useState([]);

    useEffect(() => {
        setOrderRender([{
            key: props.order.id,
            customer: props.order.data.customer ? `${props.order.data.customer.first_name} ${props.order.data.customer.last_name}`
                : `${props.order.data.shipping_address.first_name} ${props.order.data.shipping_address.last_name}`,
            order_date: timeSince(props.order.production_update_date ? props.order.production_update_date : props.order.data.created_at),
            status: props.order.status,
            address: customerAddress(props.order.data.shipping_address || props.order.data.billing_address),
            prod_status: props.order.production_status ? props.order.production_status : 'processing',
            order: props.order,
            external_id: props.order.external_id,
            id: props.order.id,
            production_update_date: props.order.production_update_date ? props.order.production_update_date : props.order.data.created_at,
            variant_images: props.order.variant_images,
            variant_stocks: props.order.variant_stocks,
        }])
    }, [props.order]);

    const itemTableColumns = [
        {
            title: '',
            dataIndex: 'image',
            render: (item: string) => <Image src={item} width={75}/>
        },
        {
            title: 'Product',
            dataIndex: 'product'
        },
        {
            title: 'Size / Color',
            dataIndex: 'variant'
        },
        {
            title: 'In Stock',
            dataIndex: 'stock'
        },
        {
            title: 'Qty. Ordered',
            dataIndex: 'qty'
        }
    ]

    const orderColumns = [
        {
            title: 'Customer',
            dataIndex: 'customer',
        },
        {
            title: 'Shipping Address',
            dataIndex: 'address'
        },
        {
            title: 'Production Status',
            dataIndex: 'prod_status',
            render: (tag: any) => {
                return (<Tag color={tag === 'processing' ? 'yellow' : 'green'} key={tag}>
                    {tag?.toUpperCase()}
                </Tag>)
            },
            width: 200
        },
        {
            title: 'Order Status',
            dataIndex: 'status',
            render: (tag: any) => {
                return (<Tag color={tag === 'closed' ? 'green' : 'yellow'} key={tag}>
                    {tag?.toUpperCase()}
                </Tag>)
            },
            width: 150,
        },
        {
            title: 'Time Since Last Updated',
            dataIndex: 'order_date',
            width: 200,
        }
    ]

    return (
        <Table columns={orderColumns as any}
               pagination={false}
               expandable={{
                   expandedRowRender: ({order}) => {
                       const data = order.data.line_items
                           .filter((item: any) => item.fulfillment_service.startsWith('instaprint'))
                           .map((item: any) => {
                               return {
                                   key: item.id,
                                   product: item.title,
                                   variant: item.variant_title,
                                   qty: item.quantity,
                                   image: order.variant_images[item.sku],
                                   stock: order.variant_stocks[item.sku],
                                   id: item.id,
                                   properties: item.properties
                               }
                           });
                       return (
                           <Content>
                               <Row gutter={8}>
                                   <Col span={12}>
                                       <Table pagination={false} dataSource={data} columns={itemTableColumns}/>
                                   </Col>
                                   <Col span={6}>
                                       <Order isAdmin={false} order={order}/>
                                   </Col>
                                   <Col span={6}>
                                       <BillingData order={order}
                                                    isAdmin={false}
                                       />
                                   </Col>
                               </Row>
                           </Content>
                       )
                   },
                   showExpandColumn: false,
                   expandedRowKeys: [props.order.id]
               }}
               dataSource={orderRender}/>
    )
}

export default UserOrder;