import React, {useEffect, useRef, useState} from "react"
import {toast} from "react-toastify";
import Product from "./Product";
import {blankVariant} from "../deo/ProductData";
import {Table, Image, Space, Button, Input, InputRef, Drawer, Modal} from "antd";
import {SearchOutlined} from "@ant-design/icons";
import {FilterConfirmProps} from "antd/es/table/interface";
import {ColumnType} from "antd/lib/table";
import Highlighter from 'react-highlight-words';
import {Content} from "antd/lib/layout/layout";
import WelcomeScreen from "../home/WelcomeScreen";

interface DataType {
    key: string;
    title: string;
    brand: string;
    model: string;
}

type DataIndex = keyof DataType;

function Products(props) {
    const [data, setData] = useState(props.products);
    const [productData, setProductData] = useState([]);
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef<InputRef>(null);
    const [visible, setVisible] = useState(false);

    useEffect(() => {
        const listOfData = data.map((d) => {
            return {
                key: d.id,
                image: d.image,
                title: d.title || '',
                brand: d.brand,
                model: d.model,
            }
        });
        setProductData(listOfData);
    }, [setData]);
    const handleDelete = (id, e: any): void => {
        setData(data.filter((item) => item.id !== id));
        $.ajax({
            type: "DELETE",
            url: `/admin/product/${id}`,
            success: function () {
                toast.success('Product Deleted!')
            },
            error: function (response) {
                let filteredSet = props.products.filter((item) => item.id === id);
                setData([
                    ...data,
                    filteredSet
                ]);
                toast.error(response.error || 'An error occurred');
            }
        })
    }

    const createNewProduct = (newProduct, callback) => {
        if (!newProduct) return;
        $.ajax({
            type: 'POST',
            url: "/admin/product",
            data: {
                product: newProduct,
            },
            success: function (response) {
                setData(response.products);
                setVisible(false);
                callback()
            },
            error: function (response) {
                callback(response);
            }
        })
    }
    const handleSearch = (
        selectedKeys: string[],
        confirm: (param?: FilterConfirmProps) => void,
        dataIndex: DataIndex,
    ) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const handleReset = (
        clearFilters: () => void,
        dataIndex: DataIndex,
        confirm: (param?: FilterConfirmProps) => void
    ) => {
        clearFilters();
        setSearchText('');
        setSearchedColumn(dataIndex)
        confirm();
    };

    const getColumnSearchProps = (dataIndex: DataIndex): ColumnType<DataType> => ({
        filterDropdown: ({setSelectedKeys, selectedKeys, confirm, clearFilters}) => (
            <div style={{padding: 8}}>
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
                    style={{marginBottom: 8, display: 'block'}}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
                        icon={<SearchOutlined/>}
                        size="small"
                        style={{width: 90}}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters, dataIndex, confirm)}
                        size="small"
                        style={{width: 90}}
                    >
                        Reset
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered: boolean) => (
            <SearchOutlined style={{color: filtered ? '#1890ff' : undefined}}/>
        ),
        onFilter: (value, record) => {
            if (!record[dataIndex]) return null
            return record[dataIndex]
                .toString()
                .toLowerCase()
                .includes((value as string).toLowerCase())
        },
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: text =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{backgroundColor: '#ffc069', padding: 0}}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });

    const productColumns = [
        {
            title: 'Image',
            dataIndex: 'image',
            key: 'image',
            render: ((img, data) => {
                return (<Image key={data.id} width={50} src={img}/>)
            })
        },
        {
            title: 'Title',
            dataIndex: 'title',
            key: 'title',
            filters: props.products.map((product) => {
                return {
                    text: product.title,
                    value: product.title
                }
            }),
            ...getColumnSearchProps('title'),
        },
        {
            title: 'Brand',
            dataIndex: 'brand',
            key: 'brand',
            filters: props.products.map((product) => {
                return {
                    text: product.brand,
                    value: product.brand
                }
            }),
            ...getColumnSearchProps('brand'),
        },
        {
            title: 'Model',
            dataIndex: 'model',
            key: 'model',
            filters: props.products.map((product) => {
                return {
                    text: product.model,
                    value: product.model
                }
            }),
            ...getColumnSearchProps('model'),
        },
        {
            title: 'Options',
            key: 'id',
            render: ((data, product) => {
                return (
                    <Space key={product.id}>
                        <Button onClick={() => window.location.href = `/admin/product/${product.key}`} type="primary">
                            Edit
                        </Button>
                        <Button onClick={(e) => handleDelete(product.key, e)} type="primary" danger>
                            Delete
                        </Button>
                    </Space>
                )
            })
        }
    ]

    return (
        <Content>
            <Space size={'middle'}>
                <Button type={'primary'} block onClick={() => setVisible(true)}>Add Product</Button>
            </Space>
            <Modal title="Add Product" visible={visible}
                   width={'80%'}
                   okText={'Done'}
                   onOk={() => setVisible(false)}
                   onCancel={() => setVisible(false)}>
                <Product
                    product={{
                        title: '',
                        image: '',
                        brand: '',
                        model: '',
                        id: '',
                        print_data: {width: '', height: '', top: '', left: ''}
                    }}
                    variants={[blankVariant]}
                    isNew={true}
                    handleSave={createNewProduct}/>
            </Modal>
            <Table size={'small'} columns={productColumns} dataSource={productData}></Table>
        </Content>
    );
}

export default Products