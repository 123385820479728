import React, {useEffect, useState} from "react";
import Order from "./Order";
import BillingData from "../order/BillingData";
import {Image, Table, Tag, Row, Col} from "antd";
import {Content} from "antd/es/layout/layout";
import {toast} from "react-toastify";
import axios from "axios";
import {customerAddress, timeSince} from "../shared/utils";

function Orders(props: { orders: any, isAdmin: boolean, isExpanded?: boolean }) {
    const [orderSet, setOrderSet] = useState([]);

    const handleShippingLabel = (data: any, orderId: any) => {
        axios.post(`/admin/orders/${orderId}/shipping_label`, data)
            .then((resp) => {
                toast.success('Label Created');
                const newOrderSet = orderSet.map((order) => {
                    if (order.id !== orderId) {
                        return order
                    } else {
                        return {
                            ...order,
                            shipping_label: resp.data
                        }
                    }
                });
                setOrderSet(newOrderSet);
            })
            .catch((error) => toast.error(error))
    }

    const doSubmit = async (data: any, orderId: any, callback: any) => {
        fetch(`/admin/orders/${orderId}`, {
            method: 'POST',
            headers: {
                'content-type': 'application/json'
            },
            body: JSON.stringify({order: data})
        }).then(async (response: any) => {
            callback();
            const respData = await response.json();
            toast.success('Order updated');
            setOrderSet(orderSet.map((order: any) => {
                if (order.id !== orderId) return order;

                return {
                    ...order,
                    order_date: timeSince(respData.production_update_date ? respData.production_update_date : respData.data.created_at),
                    status: respData.status,
                    prod_status: respData.production_status ? respData.production_status : 'processing',
                    production_update_date: respData.production_update_date ? respData.production_update_date : respData.data.created_at
                }
            }).filter((n: any) => n))
        }).catch((err: any) => callback(err));
    }

    const itemTableColumns = [
        {
            title: '',
            dataIndex: 'image',
            render: (item: string) => <Image src={item} width={75}/>
        },
        {
            title: 'Product',
            dataIndex: 'product'
        },
        {
            title: 'Size / Color',
            dataIndex: 'variant'
        },
        {
            title: 'In Stock',
            dataIndex: 'stock'
        },
        {
            title: 'Qty. Ordered',
            dataIndex: 'qty'
        }
    ]

    const orderColumns = [
        {
            title: 'Customer',
            dataIndex: 'customer',
        },
        {
            title: 'Shipping Address',
            dataIndex: 'address'
        },
        {
            title: 'Production Status',
            dataIndex: 'prod_status',
            render: (tag: any) => {
                return (<Tag color={tag === 'processing' ? 'yellow' : 'green'} key={tag}>
                    {tag.toUpperCase()}
                </Tag>)
            },
            width: 200
        },
        {
            title: 'Order Status',
            dataIndex: 'status',
            render: (tag: any) => {
                return (<Tag color={tag === 'closed' ? 'green' : 'yellow'} key={tag}>
                    {tag.toUpperCase()}
                </Tag>)
            },
            width: 150,
            filters: Array.from(new Map(props.orders.map((order: { status: string; }) => {
                return {
                    text: order.status.toUpperCase(),
                    value: order.status
                }
            }).map((item: { value: any; }) => [item.value, item])).values()),
            onFilter: (value: string, record: { status: string | string[]; }) => record.status.indexOf(value) === 0,
            defaultFilteredValue: props.isAdmin ? ['processing', 'confirmed'] : []
        },
        {
            title: 'Time Since Last Updated',
            dataIndex: 'order_date',
            width: 200,
            sorter: (a: { production_update_date: string | number | Date; }, b: {
                production_update_date: string | number | Date;
            }) => (new Date(a.production_update_date) as any) - (new Date(b.production_update_date) as any),
            defaultSortOrder: 'ascend',
        }
    ]

    useEffect(() => {
        setOrderSet(props.orders.map((order: any) => {
            if (!order.data.shipping_address && !order.data.customer) return null;

            return {
                key: order.id,
                customer: order.data.customer ? `${order.data.customer.first_name} ${order.data.customer.last_name}`
                    : `${order.data.shipping_address.first_name} ${order.data.shipping_address.last_name}`,
                order_date: timeSince(order.production_update_date ? order.production_update_date : order.data.created_at),
                status: order.status,
                address: customerAddress(order.data.shipping_address || order.data.billing_address),
                prod_status: order.production_status ? order.production_status : 'processing',
                order: order,
                external_id: order.external_id,
                id: order.id,
                production_update_date: order.production_update_date ? order.production_update_date : order.data.created_at
            }
        }).filter((n: any) => n))
    }, [props.orders])

    return (
        <Table columns={orderColumns as any}
               expandable={{
                   expandedRowRender: ({order}) => {
                       const data = order.data.line_items
                           .filter((item: any) => item.fulfillment_service.startsWith('instaprint'))
                           .map((item: any) => {
                               return {
                                   key: item.id,
                                   product: item.title,
                                   variant: item.variant_title,
                                   qty: item.quantity,
                                   image: order.variant_images[item.sku],
                                   stock: order.variant_stocks[item.sku],
                                   id: item.id,
                                   properties: item.properties
                               }
                           });
                       return (
                           <Content>
                               <Row gutter={8}>
                                   <Col span={12}>
                                       <Table pagination={false} dataSource={data} columns={itemTableColumns}/>
                                   </Col>
                                   <Col span={6}>
                                       <Order onSubmit={doSubmit} isAdmin={props.isAdmin} order={order}/>
                                   </Col>
                                   <Col span={6}>
                                       <BillingData order={order}
                                                    isAdmin={props.isAdmin}
                                                    handleShippingLabel={handleShippingLabel}
                                       />
                                   </Col>
                               </Row>
                           </Content>
                       )
                   },
                   expandRowByClick: true,
               }}
               dataSource={orderSet}/>
    )
}

export default Orders;