import React, {useEffect, useState} from 'react';
import {Form, Image, Input, Table, Typography} from "antd";
import {Content} from "antd/lib/layout/layout";
import {toast} from "react-toastify";
import {EditableCell, VariantItem} from "../shared/EditableCellProps";

export default function Variants(props) {
    const [form] = Form.useForm();
    const [variantData, setVariantData] = useState([]);
    const [editingKey, setEditingKey] = useState('');
    const isEditing = (record: VariantItem) => record.key === editingKey;
    const edit = (record: Partial<VariantItem> & { key: React.Key }) => {
        form.setFieldsValue({sku: 0, price: '', ...record});
        setEditingKey(record.key);
    };

    const cancel = () => {
        setEditingKey('');
    };

    useEffect(() => {
        setVariantData(props.variants.map((d, idx) => {
            return {
                key: idx.toString(),
                image: d.image,
                size: d.size,
                color: d.color,
                color_code: d.color_code,
                external_id: d.external_id,
                price: d.price,
                stock: d.stock,
                id: d.id,
                warehouse_stock: d.warehouse_stock
            }
        }))
    }, [props.variants])

    const variantColumns = [
        {
            title: '',
            dataIndex: 'image',
            render: ((i, data, idx) => {
                return (
                    <div>
                        <Input type={'file'}
                               onChange={(e) => props.uploadImage(e, (resp) => {
                                   const newData = [...variantData];
                                   const newItem = newData[idx];
                                   newData.splice(idx, 1, {
                                       ...newItem,
                                       image: resp.file,
                                   });
                                   setVariantData(newData)
                               })}
                               hidden={i && i.length}
                               id={'secondaryImage'}
                        />
                        <Image src={i} width={50} hidden={!i || !i.length}/>
                    </div>
                )
            })
        },
        {
            title: 'Size',
            dataIndex: 'size',
            filters: props.variants.map((v) => {
                return v.size
            }).filter((value, index, self) => {
                return self.indexOf(value) === index;
            }).map((d) => {
                return {
                    text: d,
                    value: d
                }
            }),
            filterSearch: true,
            onFilter: (value: string, record) => record.size.includes(value),
            editable: true,
        },
        {
            title: 'Color',
            dataIndex: 'color',
            filters: props.variants.map((v) => {
                return v.color
            }).filter((value, index, self) => {
                return self.indexOf(value) === index;
            }).map((d) => {
                return {
                    text: d,
                    value: d
                }
            }),
            filterSearch: true,
            editable: true,
            onFilter: (value: string, record) => record.color.includes(value),
        },
        {
            title: 'Color Code',
            dataIndex: 'color_code',
            editable: true,
        },
        {
            title: 'SKU',
            dataIndex: 'external_id',
            editable: true,
        },
        {
            title: 'Price',
            dataIndex: 'price',
            editable: true,
        },
        {
            title: 'Available',
            dataIndex: 'stock',
            editable: true,
        },
        {
            title: 'Warehouse Stock',
            dataIndex: 'warehouse_stock',
            editable: false,
        },
        props.isNew ? {
                title: 'Action',
                dataIndex: 'update',
                render: (_: any, record: VariantItem) => {
                    return editingKey === record.key ? (
                        <span>
                                <Typography.Link onClick={() => onSaveVariant(record.key)} style={{marginRight: 8}}>
                                    Save
                                </Typography.Link>
                            </span>
                    ) : (
                        <Typography.Link disabled={editingKey !== ''} onClick={() => edit(record)}>
                            Edit
                        </Typography.Link>
                    );
                },
            } :
            {
                title: 'Update',
                dataIndex: 'update',
                render: (_: any, record: VariantItem) => {
                    return editingKey === record.key ? (
                        <span>
                                <Typography.Link onClick={() => onSubmit(record.key)} style={{marginRight: 8}}>
                                    Save
                                </Typography.Link>
                                <Typography.Link onClick={cancel}>
                                    Cancel
                                </Typography.Link>
                            </span>
                    ) : (
                        <Typography.Link disabled={editingKey !== ''} onClick={() => edit(record)}>
                            Edit
                        </Typography.Link>
                    );
                },
            }
    ]

    const createData = async (key) => {
        const row = (await form.validateFields()) as VariantItem;

        const newData = [...variantData];
        const index = newData.findIndex(item => key === item.key);

        const item = newData[index];

        return {row, item, newData, index};
    }

    const onSaveVariant = async (key) => {
        const {row, newData, index} = await createData(key);
        const newItem = newData[index];
        newData.splice(index, 1, {
            ...newItem,
            ...row,
        });
        setVariantData(newData);
        setEditingKey('');
        props.updateVariantData(newData);
    }

    const onSubmit = async (key): Promise<unknown> => {
        const {row, item, newData, index} = await createData(key);

        $.ajax({
            type: "POST",
            url: `/admin/product/${props.productId}/variant`,
            data: {
                variant: row,
                variant_id: item.id,
            },
            success: function () {
                toast.success('Variant saved');
                const item = newData[index];
                newData.splice(index, 1, {
                    ...item,
                    ...row,
                });
                setVariantData(newData);
                setEditingKey('');
            },
            error: function (response) {
                toast.error(response);
            }
        })
    }

    const mergedColumns = variantColumns.map(col => {
        if (!col.editable) {
            return col;
        }
        return {
            ...col,
            onCell: (record: VariantItem) => ({
                record,
                inputType: col.dataIndex === 'stock' ? 'number' : col.dataIndex === 'color_code' ? 'color' : 'text',
                dataIndex: col.dataIndex,
                title: col.title,
                editing: isEditing(record),
            }),
        };
    });

    return (
        <Content>
            <Form form={form} component={false}>
                <Table size={'small'}
                       components={{
                           body: {
                               cell: EditableCell,
                           },
                       }}
                       rowClassName="editable-row"
                       pagination={{
                           onChange: cancel,
                       }}
                       dataSource={variantData} columns={mergedColumns}/>
            </Form>
        </Content>
    )
}