import React, {useState} from 'react';
import {toast} from "react-toastify";

function ShopifyProducts(props) {
    const [data, setData] = useState(props.products);

    const handleDelete = (id, e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        setData(data.filter((item) => item.id !== id));

        $.ajax({
            type: "DELETE",
            url: `/stores/${props.store_id}/product/${id}`,
            success: function () {
                toast.success('Product Deleted');
            },
            error: function (response) {
                setData([
                    ...data,
                ]);
                toast.error(response.error || 'An error occurred');
            }
        })
    }

    return (<table className="table table-sm">
        <thead>
        <tr>
            <th scope="col">Image</th>
            <th scope="col">Title</th>
            <th scope="col">Actions</th>
        </tr>
        </thead>
        <tbody>
        {data.sort((a, b) => (a.created_at < b.created_at) ? 1 : -1).map((product) => {
            return (
                <tr key={product.id}>
                    <td>
                        <img src={product.image} style={{width: "40px"}}
                             className="rounded mx-auto d-block" alt={product.title}/>
                    </td>
                    <td id={product.id}>
                        {product.title}
                    </td>
                    <td>
                        <button
                            onClick={() => window.location.href = `/stores/${props.store_id}/product/${product.id}`}
                            className='btn btn-outline-success btn-sm mr-1'>
                            Edit
                        </button>
                        <button onClick={(e) => handleDelete(product.id, e)}
                                className='btn btn-danger btn-sm'>
                            Delete
                        </button>
                    </td>
                </tr>
            )
        })}
        </tbody>
    </table>)
}

export default ShopifyProducts