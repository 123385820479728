import React, {useState} from "react";
import {toast} from "react-toastify";
import {Button, Card, Form, Input, Select, Tag} from "antd";

function Order(props: any) {
    const [data, setData] = useState(
        {
            status: props.order.status,
            shipping: props.order.shipping || '',
            shipping_service_name: props.order.shipping_service_name || ''
        });
    const options = [
        {key: 'scheduled', value: 'Scheduled'},
        {key: 'processing', value: 'In Progress'},
        {key: 'on_hold', value: 'On Hold'},
        {key: 'cancelled', value: 'Cancelled'},
        {key: 'closed', value: 'Closed'},
        {key: 'confirmed', value: 'Confirmed'}
    ];

    const onFinish = (values: any) => {
        props.onSubmit(values, props.order.id, (resp: any) => {
            if (resp) {
                toast.error(resp.responseJSON.errors || 'Something went wrong!');
            } else toast.success('Order update saved');
        });
    }

    return (
        <Card title={props.isAdmin ? 'Order Details' : 'Update Order Details'}
              extra={<Tag>{props.order.external_id}</Tag>}
              id="order-update">
            <Form onFinish={onFinish}>
                <Form.Item initialValue={props.order.external_id} hidden={true}>
                    <Input disabled={true} value={props.order.external_id}/>
                </Form.Item>
                <Form.Item name={['status']} initialValue={data.status}>
                    <Select disabled={!props.isAdmin} value={data.status}>
                        {options.map((option) => {
                            return (
                                <Select.Option key={option.value}
                                               value={option.key}>
                                    {option.value}
                                </Select.Option>
                            )
                        })}
                    </Select>
                </Form.Item>
                <Form.Item name={['shipping']} initialValue={data.shipping}>
                    <Input placeholder={'Tracking #'} disabled={!props.isAdmin || data.shipping} value={data.shipping}/>
                </Form.Item>
                <Form.Item name={['shipping_service_name']} initialValue={data.shipping_service_name}>
                    <Input placeholder={'Sipped Via'} disabled={!props.isAdmin || data.shipping_service_name}
                           value={data.shipping_service_name}/>
                </Form.Item>
                <Form.Item hidden={!props.isAdmin}>
                    <Button type={'primary'} htmlType={'submit'}>
                        Update
                    </Button>
                </Form.Item>
            </Form>
        </Card>
    )
}

export default Order;