import React from 'react';
import {Button, Card, Col, Divider, Form, Input, Row, Space} from "antd";
import {toast} from "react-toastify";

function BillingData(props: any) {
    const printPDF = () => {
        const newWindow= window.open(props.order.shipping_label.data.label_download.pdf)
    }

    const isBillingObjectSuccess = (label: any) => {
        if (!label) return false;

        return label.data.status === 'completed';
    }

    const resendPayment = async () => {
        const resp = await fetch(`/admin/orders/${props.order.id}/resend_approval_link`, {
            method: 'POST',
            headers: {
                'content-type': 'application/json'
            }
        });

        const jsonData = await resp.json();
        if (jsonData) toast.success('Approval link sent.')
        else toast.error('Something went wrong.')
    }

    return (
        <Card title={'Billing'}>
            <Row>
                <Col span={24}>
                    <p>Payment Status: {props.order.payment_approved ?
                        <strong className='text-success'>Approved</strong> :
                        <strong className='text-danger'>Unapproved</strong>}
                    </p>
                </Col>
                <Col span={24} hidden={!props.isAdmin || props.order.payment_approved}>
                    <Button onClick={resendPayment}>Resend Payment Notice</Button>
                </Col>
                <Divider />
            </Row>
            <Row hidden={props.order.payment_approved || props.isAdmin}>
                <Col span={24} className={'mb-2'}>
                    <Button onClick={() => window.open(props.order.payment_status_url)}>Approve Payment</Button>
                </Col>
                <Divider />
                <Col span={24}>
                    Production cost: ${Number(props.order.production_cost || '0.00').toFixed(2)}
                </Col>
            </Row>
            <Row hidden={!props.isAdmin || (props.isAdmin && !isBillingObjectSuccess(props.order.shipping_label))}>
                <Col span={24} className={'mb-2'}>
                    <Button onClick={printPDF}>
                        Print PDF
                    </Button>
                </Col>
                <Divider />
                <Col span={24}>
                    <p>Shipment cost: ${props.order.shipping_label?.data?.shipment_cost?.amount || 0.00}</p>
                </Col>
            </Row>
            <Form hidden={!props.isAdmin || isBillingObjectSuccess(props.order.shipping_label) || props.order.status == 'closed'}
                  onFinish={(data) => props.handleShippingLabel(data, props.order.id)}>
                <Form.Item name={['weight']} initialValue={`${props.order.weight} oz`}>
                    <Input disabled ={true} value={props.order.weight} placeholder={'Weight'} />
                </Form.Item>
                <Button type={'primary'} htmlType={'submit'}>
                    Create Shipping Label
                </Button>
            </Form>
        </Card>
    );
}

export default BillingData